import React, { useState } from "react";
import { Button } from "reactstrap";
import { AlertTriangle, Heart, ShoppingBag, ShoppingCart } from "react-feather";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { routes } from "../../../configs/routesConfig";
import { getCurrentCartItems } from "../../../redux/selectors/cart.selectors";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { reportShop } from "../../../services/shop.service";
import { getCookie, setCookie } from "../../../helpers/general.helper";

const Footer = (props) => {
  let footerTypeArr = ["sticky", "static", "hidden"];
  const [confirmReport, setConfirmReport] = useState(false);
  const [showReported, setShowReported] = useState(false);

  const onClickReport = () => {
    if (props.shopData.shop_catalogue_url) {
      if (!getCookie(props.shopData.shop_catalogue_url)) {
        setConfirmReport(true);
      } else {
        setShowReported(true);
      }
    }
  };

  const onConfirm = async (text) => {
    if (props.shopData) {
      const data = {
        message: text,
        shop_id: props.shopData.shop_id,
      };
      const res = await reportShop(data);
      if (res) {
        setCookie(props.shopData.shop_catalogue_url, true, 1440000); // cookie will be there for thousand days
      }
      setConfirmReport(false);
    }
  };

  return (
    <footer
      className={classnames("footer footer-light", {
        "footer-static":
          props.footerType === "static" ||
          !footerTypeArr.includes(props.footerType),
        "d-none": props.footerType === "hidden",
      })}
    >
      <p className="mb-0 clearfix">
        <span className="float-md-left d-block d-md-inline-block mt-25">
          COPYRIGHT © {new Date().getFullYear()} {props.shopData.shop_name} |
          Powered by
          <a
            href="https://www.shopsvilla.com/home"
            target="_blank"
            rel="noopener noreferrer"
          >
            Shopsvilla
          </a>
          |{" "}
          <span className="report-shop" onClick={onClickReport}>
            <AlertTriangle />
            Report this Shop
          </span>
        </span>
        <span className="float-md-right d-none d-md-block">
          <span className="align-middle">Hand-crafted & Made with</span>{" "}
          <Heart className="text-danger" size={15} />
        </span>
      </p>

      <Link to={`${routes.checkout()}`}>
        <Button color="primary" className="btn-icon scroll-top cart-button">
          {props.cartProducts.length > 0 && (
            <span className="cart-counter">{props.cartProducts.length}</span>
          )}

          <ShoppingBag size={15} />
        </Button>
      </Link>
      <SweetAlert
        // warning
        showCancel
        reverseButtons
        inputType="textarea"
        placeholder="Please give details about the Complaint"
        input
        required={false}
        show={confirmReport}
        title={"Report this shop"}
        onConfirm={onConfirm}
        onCancel={() => {
          setConfirmReport(false);
        }}
        confirmBtnText="Send"
        cancelBtnText="Cancel"
        cancelBtnCssClass="custom-cancel-btn"
        customClass="report-alert swal-wide"
      >
        <div className="report-texts">
          <p>
            If you found some unusual activity on this shop, you can report this
            shop to Shopsvilla.
          </p>
          <strong>
            Please include your Name, Phone Number, Email and complete details
            about the complaint!
          </strong>
        </div>
      </SweetAlert>
      <SweetAlert
        // warning
        reverseButtons
        show={showReported}
        title={"Already Reported"}
        onConfirm={() => setShowReported(false)}
        confirmBtnText="OK"
        showCancel={false}
      >
        You have already reported this shop!
      </SweetAlert>
    </footer>
  );
};

const mapStateToProps = (state) => ({
  cartProducts: getCurrentCartItems(state),
  shopData: state.shop.shop_data,
});

export default connect(mapStateToProps)(Footer);
