import React from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge,
} from "reactstrap";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import { Redirect, withRouter } from "react-router";
import { connect } from "react-redux";
import { logoutUser } from "../../../redux/actions/auth/auth.actions";
import PerfectScrollbar from "react-perfect-scrollbar";
import { removeItemFromCart } from "../../../redux/actions/cart/cart.actions";
import defaultImage from "../../../assets/img/elements/default-product.png";
import { routes } from "../../../configs/routesConfig";
import { getCurrentCartItems } from "../../../redux/selectors/cart.selectors";

const UserDropdownMenu = ({ history, logout }) => {
  return (
    <DropdownMenu right>
      <DropdownItem
        tag="a"
        onClick={() => {
          history.push("/shop-profile/edit");
        }}
      >
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">Edit Account</span>
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem
        tag="a"
        onClick={() => {
          logout();
          history.push("/login");
        }}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    langDropdown: false,
    cartDropdown: false,
    shoppingCart: [],
    suggestions: [],
  };

  routeChange = (data) => {
    return <Redirect to={data} />;
  };
  render() {
    const renderCartItems = this.props.cartItems.map((item) => {
      return (
        <div
          className="cart-item"
          key={`${item.product_id}_${
            item.selected_variant.length > 0
              ? item.selected_variant[0]["product_variant_id"]
              : ""
          }`}
        >
          <Media className="p-0">
            <Media className="text-center pr-0 mr-1" left>
              <Link
                to={routes.product_details(item.product_id)}
                onClick={() =>
                  this.setState({ cartDropdown: !this.state.cartDropdown })
                }
              >
                <img
                  className="cart-item-img"
                  src={item.image_url ? item.image_url : defaultImage}
                  width={100}
                  height={100}
                  style={{ objectFit: "cover" }}
                  alt="Cart Item"
                />
              </Link>
            </Media>
            <Media className="overflow-hidden pr-1 py-1 pl-0" body>
              <Link
                to={routes.product_details(item.product_id)}
                onClick={() =>
                  this.setState({ cartDropdown: !this.state.cartDropdown })
                }
              >
                <span className="item-title text-truncate text-bold-500 d-block mb-50">
                  {item.product_name}
                </span>
                <span className="item-desc font-small-2 text-truncate d-block">
                  {item.selected_variant.length
                    ? "Variant : " +
                      item.selected_variant[0]["product_variant_name"]
                    : ""}
                </span>
              </Link>
              <div className="d-flex justify-content-between align-items-center mt-1">
                <span className="align-middle d-block">
                  {item.quantity} x <strong>$ {item.main_price}</strong>
                </span>
                <Icon.X
                  className="danger"
                  size={15}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.props.removeItem({ ...item });
                  }}
                />
              </div>
            </Media>
          </Media>
        </div>
      );
    });
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
          isOpen={this.state.cartDropdown}
          toggle={() =>
            this.setState({ cartDropdown: !this.state.cartDropdown })
          }
        >
          <DropdownToggle tag="a" className="nav-link position-relative">
            <Icon.ShoppingCart size={21} />
            {this.props.cartItems.length > 0 ? (
              <Badge pill color="primary" className="badge-up">
                {this.props.cartItems.length}
              </Badge>
            ) : null}
          </DropdownToggle>
          <DropdownMenu
            tag="ul"
            right
            className={`dropdown-menu-media dropdown-cart ${
              this.props.cartItems.length === 0 ? "empty-cart" : ""
            }`}
          >
            <li
              className={`dropdown-menu-header ${
                this.props.cartItems.length === 0 ? "d-none" : "d-block"
              }`}
            >
              <div className="dropdown-header m-0">
                <h3 className="white">
                  {this.props.cartItems.length} Items In Your Cart
                </h3>
                {/* <span className="text-white">
                  {this.props.cartItems.length} Items In Your Cart
                </span> */}
              </div>
            </li>
            <PerfectScrollbar
              className="media-list overflow-hidden position-relative"
              options={{
                wheelPropagation: false,
              }}
            >
              {renderCartItems}
            </PerfectScrollbar>
            <li
              className={`dropdown-menu-footer border-top ${
                this.props.cartItems.length === 0 ? "d-none" : "d-block"
              }`}
            >
              <div
                className="dropdown-item p-1 text-center text-primary"
                onClick={() => {
                  this.props.history.push(routes.checkout());
                  this.setState({ cartDropdown: !this.state.cartDropdown });
                }}
              >
                <Icon.ShoppingCart size={15} />
                <span className="align-middle text-bold-600 ml-50">
                  Checkout
                </span>
              </div>
            </li>
            <li
              className={`empty-cart ${
                this.props.cartItems.length > 0 ? "d-none" : "d-block"
              } p-2`}
            >
              Your Cart Is Empty
            </li>
          </DropdownMenu>
        </UncontrolledDropdown>
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.props.userName}
              </span>
              {/* <span className="user-status">Available</span> */}
            </div>
            <span data-tour="user">
              <img
                src={this.props.userImg}
                className="round"
                height="40"
                width="40"
                alt="avatar"
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: getCurrentCartItems(state),
});

const mapDispatchToProps = (dispatch) => ({
  removeItem: (id) => dispatch(removeItemFromCart(id)),
  logout: () => dispatch(logoutUser()),
});

const UserDropdown = connect(null, mapDispatchToProps)(UserDropdownMenu);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NavbarUser));
