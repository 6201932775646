import { store } from "../../storeConfig/store";

export const setLoading = (isLoading) => {
  return {
    type: "SET_LOADING",
    payload: isLoading,
  };
};

export const addItemToCart = (data) => ({
  type: "ADD_TO_CART",
  payload: data,
  shop_url: getCatalogueUrl(),
});

export const checkCartExists = (catalogueUrl) => ({
  type: "CHECK_CART_EXISTS",
  payload: catalogueUrl,
});

export const removeItemFromCart = (data) => ({
  type: "REMOVE_CART_ITEM",
  payload: data,
  shop_url: getCatalogueUrl(),
});

export const updateCartItemQuantity = (data) => ({
  type: "UPDATE_QUANTITY",
  payload: data,
  shop_url: getCatalogueUrl(),
});

export const clearCart = () => ({
  type: "CLEAR_CART",
  shop_url: getCatalogueUrl(),
});

const getCatalogueUrl = () => {
  return store.getState().shop.shop_data.shop_catalogue_url;
};
