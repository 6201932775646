import { showToast } from "../../../helpers/toast.helper";

const initialState = {};

const onAddToCart = (state, { payload, shop_url }) => {
  const tempCart = [...state[shop_url]];
  const existingItems = tempCart.filter(
    (item) => item.product_id === payload.product_id
  );
  if (existingItems.length > 0) {
    let matchedProduct = null;
    if (payload.selected_variant.length > 0) {
      matchedProduct = existingItems.find(
        (item) =>
          item.selected_variant.length &&
          item.selected_variant[0].product_variant_id ===
            payload.selected_variant[0].product_variant_id
      );
    } else {
      matchedProduct = existingItems.find(
        (item) => item.selected_variant.length === 0
      );
    }
    if (matchedProduct) {
      matchedProduct["quantity"] =
        +matchedProduct["quantity"] + +payload.quantity;
      let updateIndex = -1;
      if (payload.selected_variant.length > 0) {
        updateIndex = tempCart.findIndex(
          (item) =>
            item.product_id === payload.product_id &&
            item.selected_variant.length &&
            item.selected_variant[0].product_variant_id ===
              payload.selected_variant[0]["product_variant_id"]
        );
        tempCart[updateIndex] = matchedProduct;
      } else {
        updateIndex = tempCart.findIndex(
          (item) =>
            item.product_id === payload.product_id &&
            item.selected_variant.length === 0
        );
        tempCart[updateIndex] = matchedProduct;
      }
      showToast("Item Quantity has been updated in cart");
      return { ...state, [shop_url]: tempCart };
    } else {
      showToast("Item has been added to cart");
      return {
        ...state,
        [shop_url]: [payload, ...tempCart],
      };
    }
  } else {
    showToast("Item has been added to cart");
    return {
      ...state,
      [shop_url]: [payload, ...state[shop_url]],
    };
  }
};

const onRemoveItem = (state, { payload, shop_url }) => {
  const tempCart = [...state[shop_url]];
  let removeIndex = -1;
  if (payload.selected_variant.length > 0) {
    removeIndex = tempCart.findIndex(
      (item) =>
        item.product_id === payload.product_id &&
        item.selected_variant.length &&
        item.selected_variant[0].product_variant_id ===
          payload.selected_variant[0]["product_variant_id"]
    );
  } else {
    removeIndex = tempCart.findIndex(
      (item) =>
        item.product_id === payload.product_id &&
        item.selected_variant.length === 0
    );
  }
  tempCart.splice(removeIndex, 1);
  showToast("Item has been removed from cart");
  return { ...state, [shop_url]: tempCart };
};

const onUpdateQuantity = (state, { payload, shop_url }) => {
  const tempCart = [...state[shop_url]];
  let updateIndex = -1;
  if (payload.selected_variant.length > 0) {
    updateIndex = tempCart.findIndex(
      (item) =>
        item.product_id === payload.product_id &&
        item.selected_variant[0]["product_variant_id"] ===
          payload.selected_variant[0]["product_variant_id"]
    );
  } else {
    updateIndex = tempCart.findIndex(
      (item) =>
        item.product_id === payload.product_id &&
        item.selected_variant.length === 0
    );
  }
  tempCart[updateIndex]["quantity"] = payload.quantity;
  return { ...state, [shop_url]: tempCart };
};

const onCheckCart = (state, { payload }) => {
  const tempCart = { ...state };
  if (!tempCart[payload]) {
    tempCart[payload] = [];
  }
  return tempCart;
};

export const cart = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_TO_CART":
      return onAddToCart(state, action);
    case "REMOVE_CART_ITEM":
      return onRemoveItem(state, action);
    case "CLEAR_CART":
      return { ...state, [action.shop_url]: [] };
    case "UPDATE_QUANTITY":
      return onUpdateQuantity(state, action);
    case "CHECK_CART_EXISTS":
      return onCheckCart(state, action);
    default:
      return state;
  }
};
