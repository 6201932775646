import { combineReducers } from "redux";
import customizer from "./customizer/";
import { general } from "./general/general.reducers";
import { auth } from "./auth/auth.reducers";
import { shop } from "./shop/shop.reducers";
import { cart } from "./cart/cart.reducers";

const rootReducer = combineReducers({
  general: general,
  customizer: customizer,
  auth: auth,
  shop: shop,
  cart: cart,
});

export default rootReducer;
