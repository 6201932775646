import { constants } from "../AppConstants";
import * as CryptoJS from "crypto-js";

export const getRandomByLength = (
  length,
  chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
) => {
  let string = "";
  for (let i = 0; i < length; i++) {
    string += chars[Math.floor(Math.random() * chars.length)];
  }
  return string;
};

export const setCookie = (name, value, minutes) => {
  let expires = "";
  if (minutes) {
    const date = new Date();
    date.setTime(date.getTime() + minutes * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export const getCookie = (name) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const eraseCookie = (name) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

export const encryptString = (string) => {
  return btoa(
    CryptoJS.AES.encrypt(string, constants.AES_SECRET_PASSPHRASE).toString()
  );
};

export const decryptString = (encryptedString) => {
  try {
    const jsDecrypted = atob(encryptedString);
    return CryptoJS.AES.decrypt(
      jsDecrypted,
      constants.AES_SECRET_PASSPHRASE
    ).toString(CryptoJS.enc.Utf8);
  } catch (e) {
    return "";
  }
};

export const getRandomNumber = (length = 1) => {
  let multiPly = 10;
  switch (length) {
    case 2:
      multiPly = 100;
      break;
    case 3:
      multiPly = 1000;
      break;
    default:
      break;
  }
  return Math.floor(Math.random() * multiPly);
};
