import { axiosGet, axiosPost } from "../axios/axios.config";
import { checkCartExists } from "../redux/actions/cart/cart.actions";
import { setLoading } from "../redux/actions/general/general.actions";
import {
  getProductCategories,
  getProductsSuccess,
  getShopSuccess,
  setIsValidShop,
} from "../redux/actions/shop/shop.actions";
import { store } from "../redux/storeConfig/store";

export const saveShop = async (data) => {
  store.dispatch(setLoading(true));
  const response = await axiosPost("saveshop", data);
  store.dispatch(setLoading(false));
  return response;
};

export const getShop = async (shopSlug) => {
  const response = await axiosGet("getcatalogue/" + shopSlug);
  if (response["catalogue"]) {
    store.dispatch(setIsValidShop(true));
    const shopData = response["catalogue"]["shop"][0];
    const currency = {
      INR: "₹",
      USD: "$",
    };

    shopData["currency_code"] = ["INR", "USD"].includes(
      shopData["currency_code"]
    )
      ? currency[shopData["currency_code"]]
      : shopData["currency_code"];
    store.dispatch(getShopSuccess(response["catalogue"]["shop"][0]));
    store.dispatch(getProductsSuccess(response["catalogue"]["products"]));
    store.dispatch(
      getProductCategories(response["catalogue"]["shop"][0]["shop_id"])
    );
    store.dispatch(checkCartExists(shopSlug));
    return true;
  }
  store.dispatch(setIsValidShop(false));
  return false;
};

export const checkValidShopUrl = async (name, shopId) => {
  if (shopId) {
    return await axiosGet("checkcaturl/" + name + "/" + shopId);
  } else {
    return await axiosGet("checkcaturl/" + name);
  }
};

export const getShopSeoData = async (shopId) => {
  const res = await axiosGet("getshopseo/" + shopId);
  if (res["shop_seo"]) {
    return res["shop_seo"];
  }
  return false;
};

export const reportShop = async (data) => {
  const res = await axiosPost("reportshop", data);
  if (res["status"] === "success") {
    return true;
  }
  return false;
};
