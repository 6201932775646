import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import { optionTypes } from "../../../AppConstants";
import {
  getOptionsListByName,
  getStateListByCountry,
} from "../../../redux/actions/general/general.actions";

const ShopBanner = ({
  shopData,
  getOptions,
  countryList,
  stateList,
  getStates,
}) => {
  const [derivedShopData, setDerivedShopData] = useState({});

  useEffect(() => {
    if (countryList.length === 0) getOptions(optionTypes.country_list);
    if (countryList.length && Object.keys(shopData).length) {
      const tempShopData = { ...shopData };
      const derivedData = {
        shop_country_name: "",
      };
      if (tempShopData.shop_country_id) {
        const shopCountry = countryList.filter(
          ({ id }) => id === tempShopData.shop_country_id
        );
        derivedData["shop_country_name"] = shopCountry[0]["country_name"];
        if (!stateList[tempShopData.shop_country_id]) {
          getStates(tempShopData.shop_country_id);
        }
      }
      tempShopData["shop_catalogue_header"] = JSON.parse(
        tempShopData["shop_catalogue_header"]
      );
      setDerivedShopData({ ...tempShopData, ...derivedData });
    }
  }, [countryList, shopData]);

  const getStyles = (key) => {
    const { shop_catalogue_header } = derivedShopData;
    const styles = {
      fontFamily: shop_catalogue_header[key].font_family,
      fontSize: shop_catalogue_header[key].font_size,
      color: shop_catalogue_header[key].font_color,
    };
    if (shop_catalogue_header[key].font_style !== "bold") {
      styles["fontStyle"] = shop_catalogue_header[key].font_style;
    } else {
      styles["fontWeight"] = shop_catalogue_header[key].font_style;
    }
    return styles;
  };
  const getStateName = () => {
    if (
      Object.keys(derivedShopData).length &&
      stateList[derivedShopData.shop_country_id]
    ) {
      const state = stateList[derivedShopData.shop_country_id].filter(
        ({ id }) => id === derivedShopData.shop_state_id
      );
      return state[0]["state_name"];
    }
    return "";
  };
  const getBottomInfo = () => {
    if (derivedShopData.shop_catalogue_header) {
      const data = {};
      if (derivedShopData.shop_catalogue_header.phone_number.status) {
        data["phone_number"] = ["Phone", derivedShopData.mobile_phone_primary];
      }
      if (derivedShopData.shop_catalogue_header.shop_email.status) {
        data["shop_email"] = ["Email Address", derivedShopData.shop_email];
      }
      if (derivedShopData.shop_catalogue_header.shop_website.status) {
        data["shop_website"] = ["Website", derivedShopData.shop_website];
      }
      switch (Object.keys(data).length) {
        case 1:
          return (
            <Col md={12} style={getStyles(Object.keys(data)[0])}>
              {data[Object.keys(data)[0]][0]} : {data[Object.keys(data)[0]][1]}
            </Col>
          );
        case 2:
          return (
            <>
              <Col md={3} />
              {Object.keys(data).map((key) => (
                <Col md={3} style={getStyles(key)}>
                  {data[key][0]} : {data[key][1]}
                </Col>
              ))}
              <Col md={3} />
            </>
          );
        case 3:
          return (
            <>
              <Col md={1} />
              {Object.keys(data).map((key, index) => (
                <Col md={index === 1 ? 4 : 3} style={getStyles(key)}>
                  {data[key][0]} : {data[key][1]}
                </Col>
              ))}
              <Col md={1} />
            </>
          );
        default:
      }
    }
    return "";
  };
  return (
    <div
      className="text-center p-2"
      style={{
        background: derivedShopData.shop_catalogue_header
          ? derivedShopData.shop_catalogue_header.bg_color
          : "",
      }}
    >
      {derivedShopData.shop_images && derivedShopData.shop_images.length ? (
        <div>
          <img
            src={derivedShopData.shop_images[0]["image_url"]}
            alt="brand-logo banner-logo"
            height="150"
            className="mb-1"
          />
        </div>
      ) : (
        ""
      )}
      {derivedShopData.shop_catalogue_header &&
      derivedShopData.shop_catalogue_header.shop_name.status ? (
        <p style={getStyles("shop_name")}>{derivedShopData.shop_name}</p>
      ) : (
        ""
      )}
      {derivedShopData.shop_catalogue_header &&
      derivedShopData.shop_catalogue_header.shop_tagline.status ? (
        <p style={getStyles("shop_tagline")}>{derivedShopData.shop_tagline}</p>
      ) : (
        ""
      )}
      {derivedShopData.shop_catalogue_header &&
      derivedShopData.shop_catalogue_header.shop_details.status ? (
        <p style={getStyles("shop_details")}>
          {derivedShopData.shop_add_line1}
          {derivedShopData.shop_add_line2
            ? ", " + derivedShopData.shop_add_line2
            : ""}
          {derivedShopData.shop_city ? ", " + derivedShopData.shop_city : ""}
          {derivedShopData.shop_state_id ? ", " + getStateName() : ""}
          {derivedShopData.shop_country_name
            ? ", " + derivedShopData.shop_country_name
            : ""}
          {derivedShopData.shop_postal_code
            ? ", " + derivedShopData.shop_postal_code
            : ""}
        </p>
      ) : (
        ""
      )}
      {derivedShopData.shop_catalogue_header &&
      derivedShopData.shop_catalogue_header.phone_number.status ? (
        <p style={getStyles("phone_number")}>
          Phone : {derivedShopData.mobile_phone_primary}
        </p>
      ) : (
        ""
      )}
      {derivedShopData.shop_catalogue_header &&
      derivedShopData.shop_catalogue_header.shop_email.status &&
      derivedShopData.shop_email.trim() ? (
        <p style={getStyles("shop_email")}>
          Email : {derivedShopData.shop_email}
        </p>
      ) : (
        ""
      )}

      {derivedShopData.shop_catalogue_header &&
      derivedShopData.shop_catalogue_header.shop_website.status &&
      derivedShopData.shop_website.trim() ? (
        <p style={getStyles("shop_website")}>
          Website : {derivedShopData.shop_website}
        </p>
      ) : (
        " "
      )}
      <Row>
        {/* <Col md={1} />
        {derivedShopData.shop_catalogue_header &&
        derivedShopData.shop_catalogue_header.phone_number.status ? (
          <Col md={3} style={getStyles("phone_number")}>
            Phone : {derivedShopData.mobile_phone_primary}
          </Col>
        ) : (
          ""
        )}
        {derivedShopData.shop_catalogue_header &&
        derivedShopData.shop_catalogue_header.shop_email.status ? (
          <Col md={4} style={getStyles("shop_email")}>
            Email Address : {derivedShopData.shop_email}
          </Col>
        ) : (
          ""
        )}

        {derivedShopData.shop_catalogue_header &&
        derivedShopData.shop_catalogue_header.shop_website.status ? (
          <Col md={3} style={getStyles("shop_website")}>
            Website : {derivedShopData.shop_website}
          </Col>
        ) : (
          " "
        )}
        <Col md={1} /> */}
        {/* {getBottomInfo()} */}
      </Row>
    </div>
  );
};

const mapStateToProps = ({
  shop: { shop_data },
  general: { country_list, state_list },
}) => ({
  shopData: shop_data,
  countryList: country_list,
  stateList: state_list,
});

const mapDispatchToProps = (dispatch) => ({
  getOptions: (type) => dispatch(getOptionsListByName(type)),
  getStates: (countryId) => dispatch(getStateListByCountry(countryId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopBanner);
