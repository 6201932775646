const initialState = {
  user_id: null,
  shop_id: null,
};

const onLogoutEvent = () => {
  localStorage.removeItem("token");
  return initialState;
};

export const auth = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USERID_SHOPID": {
      return { ...state, ...action.payload };
    }
    case "LOGOUT_USER": {
      return onLogoutEvent();
    }
    case "SET_SHOPID": {
      return { ...state, shop_id: action.payload };
    }
    default:
      return state;
  }
};
