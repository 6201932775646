const initialState = {
  shop_data: {},
  products: [],
  categories: [],
  is_valid_shop: false,
  delivery_type: "cod",
};

export const shop = (state = initialState, action) => {
  switch (action.type) {
    case "GET_SHOP_SUCCESS":
      return {
        ...state,
        shop_data: {
          ...action.payload,
          shop_settings: action.payload.shop_settings[0],
        },
      };
    case "GET_PRODUCTS_SUCCESS":
      return { ...state, products: action.payload };
    case "GET_CATEGORIES_SUCCESS":
      return { ...state, categories: action.payload };
    case "SET_ID_VALID_SHOP":
      return { ...state, is_valid_shop: action.payload };
    case "SET_DELIVERY_TYPE":
      return { ...state, delivery_type: action.payload };
    default:
      return state;
  }
};
