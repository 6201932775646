import { createSelector } from "reselect";

const getShopUrl = (state) => state.shop.shop_data.shop_catalogue_url;

const getAllCarts = (state) => state.cart;

export const getCurrentCartItems = createSelector(
  [getShopUrl, getAllCarts],
  (url, carts) => (carts[url] ? carts[url] : [])
);
