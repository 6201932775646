import { axiosGet } from "../../../axios/axios.config";

export const setLoading = (isLoading) => {
  return {
    type: "SET_LOADING",
    payload: isLoading,
  };
};

export const setOptions = (options, type) => ({
  type: "SET_OPTIONS_LIST",
  payload: { options, type },
});

export const setStateOptions = (options, countryId) => ({
  type: "SET_STATES_LIST",
  payload: { options, countryId },
});

export const getOptionsListByName = (optionType) => {
  return (dispatch) => {
    axiosGet(`getmaster/${optionType}`).then((response) => {
      if (response[optionType]) {
        if (!Array.isArray(response[optionType])) {
          response[optionType] = Object.values(response[optionType]);
        }
        dispatch(setOptions(response[optionType], optionType));
      }
    });
  };
};

export const getStateListByCountry = (countryId) => {
  return (dispatch) => {
    axiosGet(`getmaster/state_list/${countryId}`).then((response) => {
      if (response["state_list"]) {
        dispatch(
          setStateOptions(Object.values(response["state_list"]), countryId)
        );
      }
    });
  };
};
