import { Flip, toast } from "react-toastify";
export const showToast = (message = "success") => {
  toast(message, {
    position: toast.POSITION.TOP_CENTER,
    className: "custom-toast",
    transition: Flip,
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
  });
};
