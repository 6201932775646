export const setUserIdShopId = (data) => {
  return {
    type: "SET_USERID_SHOPID",
    payload: data,
  };
};

export const setShopId = (shopId) => {
  return {
    type: "SET_SHOPID",
    payload: shopId,
  };
};

export const logoutUser = () => {
  return {
    type: "LOGOUT_USER",
  };
};
