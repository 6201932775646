import { store } from "../redux/storeConfig/store";

const getCatalogueUrl = () => {
  const state = store.getState();
  if (state.shop.shop_data && state.shop.shop_data.shop_catalogue_url) {
    return state.shop.shop_data.shop_catalogue_url;
  } else {
    return "";
  }
};

export const routes = {
  home: () => `/${getCatalogueUrl()}`,
  product_details: (productName, id) => {
    const name = productName.toLowerCase().replaceAll(" ", "-");
    return `/${getCatalogueUrl()}/product-detail/${name}/${btoa(id)}`;
  },
  checkout: () => `/${getCatalogueUrl()}/checkout`,
};
