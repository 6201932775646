export const constants = {
  BACKEND_URL: process.env.REACT_APP_API_URL,
  API_URL: process.env.REACT_APP_API_URL,
  API_KEY: process.env.REACT_APP_API_KEY,
  API_STATUSES: ["success", "fail", "error"],
  AES_SECRET_PASSPHRASE: process.env.REACT_APP_AES_SECRET_PASSPHRASE,
};

export const optionTypes = {
  country_list: "country_list",
  state_list: "state_list",
  business_categories: "business_categories",
  delivery_types: "delivery_types",
  order_status: "order_status",
  shop_image_types: "shop_image_types",
};
