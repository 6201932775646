const initialState = {
  loading: false,
  country_list: [],
  state_list: {}, // by country
  business_categories: [],
  delivery_types: [],
  order_status: [],
  shop_image_types: [],
};

export const general = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOADING": {
      return { ...state, loading: action.payload };
    }
    case "SET_OPTIONS_LIST": {
      return { ...state, [action.payload.type]: action.payload.options };
    }
    case "SET_STATES_LIST": {
      return {
        ...state,
        state_list: {
          ...state.state_list,
          [action.payload.countryId]: action.payload.options,
        },
      };
    }
    default:
      return state;
  }
};
