import { axiosGet } from "../../../axios/axios.config";

export const setLoading = (isLoading) => {
  return {
    type: "SET_LOADING",
    payload: isLoading,
  };
};

export const getProductsSuccess = (data) => ({
  type: "GET_PRODUCTS_SUCCESS",
  payload: data,
});

export const getShopSuccess = (data) => ({
  type: "GET_SHOP_SUCCESS",
  payload: data,
});

export const getCategoriesSuccess = (data) => ({
  type: "GET_CATEGORIES_SUCCESS",
  payload: data,
});

export const setIsValidShop = (isValid) => ({
  type: "SET_ID_VALID_SHOP",
  payload: isValid,
});

export const setDeliveryType = (deliveryType) => ({
  type: "SET_DELIVERY_TYPE",
  payload: deliveryType,
});

export const getShop = (shopSlug) => {
  return (dispatch) => {
    axiosGet("getcatalogue/" + shopSlug).then((response) => {
      if (response["shop"]) {
        const currency = {
          INR: "₹",
          USD: "$",
        };
        const data = response["shop"];
        data["currency_code"] = ["INR", "USD"].includes(
          response["shop"]["currency_code"]
        )
          ? currency[response["shop"]["currency_code"]]
          : response["shop"]["currency_code"];
        dispatch(getShopSuccess(data));
      }
    });
  };
};

export const getProducts = (shopId) => {
  return (dispatch) => {
    setLoading(true);
    axiosGet("getproducts/" + shopId).then((response) => {
      setLoading(false);
      if (response["products"]) {
        dispatch(getProductsSuccess(response["products"]));
      }
    });
  };
};

export const getProductCategories = (shopId) => {
  return (dispatch) => {
    setLoading(true);
    axiosGet("getproductcategories/" + shopId).then((response) => {
      setLoading(false);
      if (response["product_categories"]) {
        dispatch(getCategoriesSuccess(response["product_categories"]));
      }
    });
  };
};
