import { createStore, applyMiddleware, compose } from "redux";
import createDebounce from "redux-debounced";
import thunk from "redux-thunk";
import reducers from "../reducers/rootReducer";
import logger from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const middlewares = [thunk, createDebounce()];
const persistConfig = {
  key: "root",
  whitelist: ["cart", "general"],
  storage,
};
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
}
const store = createStore(
  persistReducer(persistConfig, reducers),
  {},
  composeEnhancers(applyMiddleware(...middlewares))
);

let persistor = persistStore(store);

export { store, persistor };
