import axios from "axios";
import { constants } from "../AppConstants";
const Axios = axios.create();
const apiKey = "00331c75-39d5-44e6-23bo-0c3e8ef2196d";
let apiUrl = constants.API_URL;
if (!window.location.origin.includes("//www")) {
  apiUrl = apiUrl.replace("www.", "");
}
Axios.interceptors.request.use((request) => {
  const headers = {
    Accept: "application/json",
    "api-key": apiKey,
  };
  request.headers = headers;
  return request;
});

Axios.interceptors.response.use(
  (response) => {
    return Promise.resolve(response.data);
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      return Promise.reject(error.response.message);
    } else {
      return Promise.resolve([]);
    }
  }
);

export const axiosGet = (url) => {
  return new Promise((resolve, reject) => {
    // Axios.get(constants.API_URL + url).then(
    Axios.get(apiUrl + url).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export const axiosPost = (url, data = {}) => {
  return new Promise((resolve, reject) => {
    // Axios.post(constants.API_URL + url, data).then(
    Axios.post(apiUrl + url, data).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
      }
    );
  });
};
